import React from 'react'
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps'

const RPGMap = withScriptjs(
  withGoogleMap(() => (
    <GoogleMap
      defaultZoom={17}
      defaultCenter={{ lat: 35.663372, lng: 139.732199 }}
      defaultMapTypeId="satellite"
      defaultOptions={{
        zoomControl: true,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        styles: [
          { elementType: 'labels', stylers: [{ visibility: 'off' }] },
          { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
        ],
      }}
    />
  ))
)

function App() {
  return (
    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
      <RPGMap
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyATGWMfcccbiswZ4miHz0cZcsEtD7TSlo0"
        loadingElement={<div style={{ height: '100%' }} />}
        containerElement={<div style={{ height: '100%', flex: 1 }} />}
        mapElement={<div style={{ height: '100%' }} />}
      />
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          // fontFamily: 'Source Code Pro',
          // fontFamily: 'Phenomena',
          // fontWeight: 'bold',
          textShadow: '0 0 2vw rgba(0, 0, 0, .5)',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          pointerEvents: 'none',
          background: 'rgba(0, 0, 0, .7)',
        }}
      >
        <div style={{ fontFamily: 'VT323', fontSize: 48, color: '#fff', margin: '18px 0px' }}>
          RPG TEC
        </div>
        <table border="1" style={{ background: 'rgba(255, 255, 255, .1)' }}>
          <tbody>
            <tr>
              <th style={{ padding: 8 }}>会社名</th>
              <td style={{ padding: 8, fontSize: '80%' }}>
                <p>RPGテック合同会社</p>
                <p>英語名：RPG TEC, Inc.</p>
              </td>
            </tr>
            <tr>
              <th style={{ padding: 8 }}>設立日</th>
              <td style={{ padding: 8, fontSize: '80%' }}>2019年10月9日</td>
            </tr>
            <tr>
              <th style={{ padding: 8 }}>資本金</th>
              <td style={{ padding: 8, fontSize: '80%' }}>300万円（2019年10月9日現在）</td>
            </tr>
            <tr>
              <th style={{ padding: 8 }}>所在地</th>
              <td style={{ padding: 8, fontSize: '80%' }}>
                <p>東京都港区六本木３丁目４番３１－４０４号</p>
                <p>
                  <a href="tel:09098440906" style={{ color: '#bbdefb', textDecoration: 'none' }}>
                    090-9844-0906
                  </a>
                </p>
              </td>
            </tr>
            <tr>
              <th style={{ padding: 8 }}>代表者</th>
              <td style={{ padding: 8, fontSize: '80%' }}>
                <p>代表社員　濱邉将太</p>
              </td>
            </tr>
            <tr>
              <th style={{ padding: 8 }}>主事業</th>
              <td style={{ padding: 8, fontSize: '80%' }}>
                <p>ウェブサービスの受託開発</p>
                <p>ウェブサービスの自社開発</p>
                <p>ウェブ関連のコンサルティング業務</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default App
